<template>
  <v-app style="background-color: #f2f2f2">
    <v-layout row wrap align-center justify-center>
      <v-flex lg4 md5 sm7 xs7 class="text-center">
        <img src="@/assets/logo_onebox-horizontal-png.png" width="45%" height="45%" alt="OneBox" />
        <br />
        <br />
        <v-progress-linear
          v-if="fail_ === false"
          background-color="light-blue lighten-4"
          color="success"
          indeterminate
          rounded
        ></v-progress-linear>
        <br />
        <p v-if="fail_ === false" class="text-center">รอสักครู่</p>
        <p v-else class="text-center"><v-icon color="error">error</v-icon>&nbsp;{{errormessage}}</p>
        <br />
        <br />
        <v-btn v-if="fail_ === true" color="success" @click="gotomydrive()">ย้อนกลับ</v-btn>
      </v-flex>
    </v-layout>
  </v-app>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { mapState, mapGetters } from "vuex";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import VueCookies from "vue-cookies";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  data: function() {
    return {
      fail_: false,
      errormessage:"",
    };
  },
  created() {},
  computed: {
    ...mapState(["username", "authorize", "account_active", "color", "role_level"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataLoginDefault",
    ]),
  },
  watch: {},
  methods: {
      gotomydrive(){
          this.$router.push({ name: "myfiles" });
      },
    reset_login() {
      this.$store
        .dispatch("authorize_expired")
        .then(this.login())
        .catch((error) => {
          console.log(error);
        });
    },
    async login() {
      //เช็คว่ามี oneaccesstoken มั้ย
      if (!this.$route.query.oneaccesstoken) {
        this.fail_ = true;
        this.errormessage = "Accesss Denied !!"
        
      } else {
        let _accesstoken = this.$route.query.oneaccesstoken;
        let _taxid = this.$route.query.sellertaxid;
        let branch_no = this.$route.query.sellerbranch || "00000";

        let payload = {
          accesstoken: _accesstoken,
        };

        //localStorage.clear();
        let encodetoken = window.btoa(_accesstoken)
        // localStorage.setItem("token", encodetoken);
        // localStorage.setItem("token", _accesstoken);
        VueCookies.set('token', _accesstoken);
        VueCookies.set('token_auth', true);

        // 1 ยิง API getprofile
        let check_auth = await this.$store.dispatch("authorize_checking", payload);
        let auth = await gbfGenerate.generateToken();
        if (check_auth.status === "Access Granted") {
          // เช็คว่าถ้าไม่มี taxid ให้ไปหน้า mydrive
          if (!_taxid) {
              //console.log("no tax");
            this.$router.push({ name: "myfiles" });
          } else {
            let objIndex = this.dataBusinessProfile.findIndex((obj) => obj.branch_no === branch_no && obj.id_card_num === _taxid);
            if (objIndex === -1) {
                this.fail_ = true;
                this.errormessage = "ไม่พบบริษัท !!"
            //   Toast.fire({
            //     icon: "error",
            //     title: "ไม่พบบริษัท",
            //   });
            } else {
              let databiz_id = this.dataBusinessProfile[objIndex].id;
              let payload_bu = {
                taxid: _taxid,
                accesstoken: _accesstoken,
                biz_id: databiz_id,
                branch_no: this.dataBusinessProfile[objIndex].branch_no || '00000'
              };
              // 2 ยิง API Login Business
              this.$store.dispatch("switchaccount_business", payload_bu).then(async (msg) => {
                if (msg.status === "Business Access Granted") {
                  let payload = {
                    receiver_id: this.dataAccountActive.business_info.business_id,
                    receiver_type: "2",
                  };
                  // 3 ยิง API getmyinbox เพื่อหา system etax
                  try {
                    let response = await this.axios.post(process.env.VUE_APP_SERVICE_GET_INBOX + "/api/getmyinbox", payload, {
                      withCredentials: false,
                      headers: { Authorization: auth.code },
                    });

                    if (response.data.status === "OK") {
                      let objIndex = response.data.result.findIndex((obj) => obj.system === "ETAX");
                      if (objIndex === -1) {
                          // this.fail_ = true;
                          // this.errormessage = "ไม่พบ inbox ETAX !!"
                        // Toast.fire({
                        //   icon: "error",
                        //   title: "ไม่พบ inbox ETAX",
                        // });
                         this.$router.push({
                                path: "/myinbox-system",
                                query: { type: 2}
                          });
                      } else {
                        let systemid = "";
                        console.log(systemid);
                        // 4 เอาข้อมูลมาเพื่อ เปลี่ยนไปหน้า myinbox-sourcesystem
                        
                            this.$router.push({
                                path: "/myinbox-sourcesystem",
                                query: { type: 2, sourcesystem: "ETAX",systemid:response.data.result[objIndex]["system_id"]}
                           });
                      }
                    } else {
                      Toast.fire({
                        icon: "error",
                        title: response.data.errorMessage,
                      });
                    }
                  } catch (ex) {
                    Toast.fire({
                      icon: "error",
                      title: ex,
                    });
                    console.log(ex);
                  }
                } else {
                  console.log("logout_1");
                  //this.$router.push("logout");
                  this.$router.push({ name: "myfiles" });
                }
              });
            }
          }
        } else {
          //console.log("logout");
          //this.$router.push("logout");
        //   Toast.fire({
        //     icon: "error",
        //     title: "ไม่สามารถ login ได้",
        //   });
          this.fail_ = true;
           this.errormessage = "ไม่สามารถ login ได้ !!"
        }
      }
    },
  },
  mounted() {
    console.log(",kkkkkkkkk", this.$route.query);
    this.reset_login();
  },
};
</script>
